import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    FolderOutlined,
    FrownOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    PlaySquareOutlined,
    SafetyCertificateOutlined,
    ThunderboltOutlined,
    UnlockOutlined,
    VideoCameraOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {
    Alert,
    Button,
    Col,
    Image,
    Modal,
    Popconfirm,
    Row,
    Skeleton,
    Space,
    Tag,
    Tooltip,
    Typography
} from "antd";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import LoginSignupConfirmButton from "../../Components/LoginSignupConfirmButton";
import TopUpForm from "../../Wallet/TopUpForm";
import { purchaseCollection } from "../../actions/user";
import { CERTIFICATE_STATUS } from "../../const/certificate.const";
import {
    evaluateCollectionCertification,
    requestCollectionCertification
} from "../../actions/certification";
import Cupoints from "../../Components/Cupoints";
import { Link } from "react-router-dom";
import CertificateBadgePNG from "../../assets/images/award.png";
import CollectionThumbnailPreviewer from "../../Components/CollectionThumbnailPreviewer";

const { Text, Title } = Typography;

const CertificationInfoCard = ({
    collection,
    isAuthenticated = false,
    collectionNetPrice,
    currentUser,
    hasAccessToEntireCollection = false,
    certificateInfo,
    purchaseCollection,
    collectionLoadingStatus,
    loadingAuthenticatedUser
}) => {
    const dispatch = useDispatch();

    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const [showEvaluateModal, setShowEvaluateModal] = useState(false);

    const getPurchaseAmount = () => {
        if (!collection.promotion)
            return collectionNetPrice + collection.certification.price;

        const promotionDiscount = parseFloat(
            collection.promotion.promoDiscount
        );
        return (
            collectionNetPrice +
            Math.ceil(collection.certification.price * (1 - promotionDiscount))
        );
    };

    const handleOnPurchaseClick = () => {
        // check for balance
        if (currentUser.primaryWallet.cupointsBalance >= getPurchaseAmount()) {
            purchaseCollection(collection, setPurchaseLoading, true);
        } else {
            setShowTopUpModal(true);
        }
    };

    const confirmEvaluation = () => {
        dispatch(evaluateCollectionCertification(collection.id));
        // setShowEvaluateModal(true);
    };

    const requestCertificate = () => {
        dispatch(requestCollectionCertification(collection.id));
    };

    // Insufficient Balance Content
    const InsufficientBalanceModalContent = () => {
        if (hasAccessToEntireCollection) {
            return (
                <div className="modal-body">
                    <Space>
                        <Title level={3}>
                            <UnlockOutlined />
                        </Title>
                        <Title level={3}>
                            All modules and videos are now unlocked.
                        </Title>
                    </Space>
                    <br />
                    <Text>Thank you for your purchase!</Text>
                </div>
            );
        } else if (
            currentUser.primaryWallet.cupointsBalance >= getPurchaseAmount()
        ) {
            return (
                <div style={{ padding: 25 }}>
                    <Space>
                        <Title level={3}>
                            <CheckCircleOutlined />
                        </Title>
                        <Title level={3}>Your balance is now sufficient</Title>
                    </Space>
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Button
                        type="primary"
                        loading={purchaseLoading}
                        icon={<ThunderboltOutlined />}
                        onClick={handleOnPurchaseClick}>
                        Purchase full course @ {getPurchaseAmount()}
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ padding: 25 }}>
                    <Title level={3}>Insufficient Balance</Title>
                    <Space size={"large"}>
                        <Text>Looks like you are short</Text>
                        <Text>
                            {collectionNetPrice +
                                collection.certification.price -
                                currentUser.primaryWallet.cupointsBalance}{" "}
                            cupoints
                        </Text>
                    </Space>

                    <br />
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Space direction="vertical">
                        <Text>
                            Click the button below to refill your balance.
                        </Text>
                        <TopUpForm marginTop={0} />
                    </Space>
                </div>
            );
        }
    };

    const CertificateEvaluateModalContent = () => {
        return (
            <div className="modal-body">
                TODO: Show generated coupon code for certificate
            </div>
        );
    };

    const CupointsSection = () => {
        return (
            <div>
                <Cupoints
                    points={getPurchaseAmount()}
                    tooltipEnabled={false}
                    secondaryWithinBrackets={false}></Cupoints>
            </div>
        );
    };

    const CertificateNotPurchasedSection = () => {
        return (
            <>
                {/* Show Collection Price */}
                <CupointsSection />

                {/* If evaluation not exist, show Free Trial button */}
                {certificateInfo?.evaluationShow && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Popconfirm
                            title="Are you sure to evaluate this course?"
                            onConfirm={confirmEvaluation}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button
                                block
                                onClick={(e) => e.stopPropagation()}
                                loading={certificateInfo?.evaluationProgress}>
                                Free Trial
                            </Button>
                        </Popconfirm>
                        <Modal
                            visible={showEvaluateModal}
                            onCancel={() => setShowEvaluateModal(false)}
                            maskClosable={false}
                            footer={null}>
                            <CertificateEvaluateModalContent />
                        </Modal>
                    </Space>
                )}

                {/* If evaluation not exist, show Trial expiration information */}
                {certificateInfo.evaluationExists && (
                    <Alert
                        message={
                            certificateInfo.evaluationExpired
                                ? "Your free trial has expired!"
                                : "You are in trial period!"
                        }
                        description={
                            certificateInfo.evaluationExpired ? (
                                <>
                                    To continue to use this certificate, you
                                    need to purchase full certificate.
                                </>
                            ) : (
                                <>
                                    Your trial period ends in{" "}
                                    {certificateInfo.evaluationEndsIn}. Consider
                                    purchase to keep using certificate long
                                    time.
                                </>
                            )
                        }
                        icon={
                            certificateInfo.evaluationExpired ? (
                                <FrownOutlined />
                            ) : (
                                <WarningOutlined />
                            )
                        }
                        type={
                            certificateInfo.evaluationExpired
                                ? "error"
                                : "warning"
                        }
                        showIcon
                    />
                )}
            </>
        );
    };

    const CertificatePurchasedAndNotExpired = () => {
        return (
            <div className="certificate-purchased-not-expired-section">
                {/* When certificate not requested */}
                {certificateInfo.certificateStatus ===
                    CERTIFICATE_STATUS.NOT_REQUESTED && (
                    <>
                        <Alert
                            message={"Learning Period"}
                            description={
                                <>
                                    Now you are elligible for request
                                    certificate. You have{" "}
                                    <b>{certificateInfo.certificateEndsIn}</b>{" "}
                                    to complete course and meet our minimum
                                    performance level. And then you can request
                                    certificate.
                                </>
                            }
                            type="info"
                            showIcon
                            icon={<InfoCircleOutlined />}
                        />
                        <Popconfirm
                            title="Are you sure you want to request certification?"
                            onConfirm={requestCertificate}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button
                                block
                                onClick={(e) => e.stopPropagation()}
                                loading={
                                    certificateInfo?.certificateStatusProgress
                                }>
                                Request Certificate
                            </Button>
                        </Popconfirm>
                    </>
                )}

                {/* When certificate requested, but not approved yet */}
                {certificateInfo.certificateStatus ===
                    CERTIFICATE_STATUS.REQUESTED && (
                    <Alert
                        message={"Evaluation In-progress"}
                        description={
                            <>
                                Thank you for submitting your certification
                                request. We are currently evaluating your
                                performance as part of the certification
                                process. We appreciate your patience during this
                                review.
                            </>
                        }
                        type="info"
                        showIcon
                        icon={<ClockCircleOutlined />}
                    />
                )}

                {/* Certificate Rejected */}
                {certificateInfo.certificateStatus ===
                    CERTIFICATE_STATUS.REJECTED && (
                    <>
                        <Alert
                            message={"Certification Rejected"}
                            description={
                                <>
                                    You did not meet the minimum performance
                                    level required. Please complete the
                                    necessary learning and reapply for the
                                    certification.
                                </>
                            }
                            type="error"
                            showIcon
                            icon={<FrownOutlined />}
                        />
                        <Popconfirm
                            title="Are you sure you want to re-request certification?"
                            onConfirm={requestCertificate}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button
                                block
                                onClick={(e) => e.stopPropagation()}
                                loading={
                                    certificateInfo?.certificateStatusProgress
                                }>
                                Re-request Certificate
                            </Button>
                        </Popconfirm>
                    </>
                )}
            </div>
        );
    };

    const CertificatePurchased = () => {
        return (
            <>
                {certificateInfo.certificateExpired ? (
                    <Alert
                        message={"Certificate Expired"}
                        description={<>Your certification has expired.</>}
                        type="error"
                        showIcon
                        icon={<FrownOutlined />}
                    />
                ) : (
                    <CertificatePurchasedAndNotExpired />
                )}
            </>
        );
    };

    // Certification Action Buttons
    const CertificationActionButtons = () => {
        return (
            <>
                {isAuthenticated ? (
                    <>
                        {/* If certificate not purchased yet: START */}
                        {!certificateInfo.certificatePurchased && (
                            <CertificateNotPurchasedSection />
                        )}

                        <Space
                            direction="vertical"
                            style={{ width: "100%", marginTop: "5px" }}>
                            {!certificateInfo.certificatePurchased ? (
                                //  If certificate not purchased, show purchase button
                                <>
                                    <Popconfirm
                                        title="Are you sure to purchase this course with certification?"
                                        onConfirm={handleOnPurchaseClick}
                                        onCancel={(e) => {
                                            e.stopPropagation();
                                        }}
                                        okText="Yes"
                                        cancelText="No">
                                        <Button
                                            block
                                            type="primary"
                                            loading={purchaseLoading}
                                            icon={<ThunderboltOutlined />}
                                            onClick={(e) => {
                                                // e.stopPropagation();
                                            }}>
                                            Purchase all
                                        </Button>

                                        {collection.promotion && (
                                            <>
                                                <div className="promo-apply-label">
                                                    {parseFloat(
                                                        collection.promotion
                                                            .promoDiscount
                                                    ) * 100}
                                                    {"% "}
                                                    PROMOTION APPLIED
                                                </div>
                                            </>
                                        )}
                                        {/* </Tooltip> */}
                                    </Popconfirm>
                                    <Modal
                                        visible={showTopUpModal}
                                        onCancel={() =>
                                            setShowTopUpModal(false)
                                        }
                                        maskClosable={false}
                                        footer={null}>
                                        <InsufficientBalanceModalContent />
                                    </Modal>
                                </>
                            ) : (
                                // if certificate purchased, show details
                                <CertificatePurchased />
                            )}
                        </Space>
                    </>
                ) : (
                    <>
                        {!loadingAuthenticatedUser ? (
                            <Space
                                direction="vertical"
                                style={{ width: "100%" }}>
                                <CupointsSection />
                                <LoginSignupConfirmButton
                                    id={
                                        "collection-certification-card-action-button"
                                    }
                                    className={
                                        "collection-certification-purchase-btn"
                                    }
                                    title={
                                        "You must be logged in to make a purchase."
                                    }
                                    buttonType="primary"
                                    buttonText={"Get a course certification"}
                                    buttonBlock={true}
                                    icon={<ThunderboltOutlined />}
                                />
                            </Space>
                        ) : (
                            <>
                                <Skeleton
                                    active={true}
                                    title={false}
                                    block={false}
                                    size={"large"}
                                    paragraph={{ rows: 1 }}
                                />
                                <Skeleton.Button
                                    active={true}
                                    size={"large"}
                                    block={true}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    const CertificationCoverImage = () => {
        return (
            <CollectionThumbnailPreviewer
                videoType={collection?.certification?.promotionVideoType}
                videoUrl={collection?.certification?.promotionVideoUrl}
                thumbnail={
                    <Image
                        src={
                            collection?.certification?.image ||
                            collection?.coverImage.image
                        }
                        preview={false}
                        width={"100%"}
                    />
                }
            />
        );
    };

    const CertificateBadge = () => {
        return (
            <>
                <div className="course-certificate-badge-container">
                    <Tooltip
                        placement="bottom"
                        title={"Get a validated certificate on this course"}>
                        <img
                            src={CertificateBadgePNG}
                            className="course-certificate-badge"
                        />
                    </Tooltip>
                </div>
            </>
        );
    };

    const CertificateIssuedMessage = () => {
        return (
            <>
                {/* Certificate Issued */}
                {certificateInfo &&
                    certificateInfo.certificateStatus ===
                        CERTIFICATE_STATUS.ISSUED && (
                        <>
                            <Alert
                                message={"Congratulations!"}
                                style={{ marginBottom: "1rem" }}
                                description={
                                    <>
                                        You have successfully achieved your{" "}
                                        <b>{collection.name}</b> Certification.
                                        Your hard work and dedication have paid
                                        off. Well done!
                                    </>
                                }
                                type="info"
                                showIcon
                                icon={<SafetyCertificateOutlined />}
                            />
                        </>
                    )}
            </>
        );
    };

    const secondsToHms = (seconds, includeSeconds = true) => {
        seconds = Number(seconds);
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor((seconds % 3600) % 60);
        const hDisplay = h > 0 ? h + "hr " : "";
        const mDisplay = m > 0 ? m + "min " : "";
        const sDisplay = includeSeconds && s > 0 ? s + "sec" : "";
        return hDisplay + mDisplay + sDisplay;
    };

    const DummyCertificate = () => {
        return <>
            <div className="dummy-certificate"></div>
        </>;
    };

    // Certification Info Card Rendered
    return (
        <div className="certification-info-card">
            <CertificateIssuedMessage />

            <Row>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={14}
                    xl={14}
                    style={{ paddingRight: "1rem" }}>
                    <div className="collection-info-card-text-container">
                        {/* Title */}
                        <Title level={4} className="collection-info-card-title">
                            {collection.name}
                        </Title>

                        {/* Collection Counts */}
                        <div className="collection-details-bullets">
                            {collection?.moduleCount > 0 && (
                                <span>
                                    <FolderOutlined />
                                    {collection.moduleCount} Modules
                                </span>
                            )}
                            {collection?.videoCount > 0 && (
                                <span>
                                    <VideoCameraOutlined />
                                    {collection.videoCount} Videos
                                </span>
                            )}
                            {collection?.totalDuration > 0 && (
                                <span>
                                    <PlaySquareOutlined />
                                    {secondsToHms(
                                        collection.totalDuration || 0,
                                        false
                                    )}
                                    total duration
                                </span>
                            )}
                        </div>

                        {/* Authors */}
                        <div className="authors">
                            <Text type="secondary">
                                Taught by{" "}
                                <Link
                                    to={`/creators/${collection.primaryAuthor.id}`}>{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Link>
                            </Text>

                            {/* University Info */}
                            {collection.university ? (
                                <>
                                    {collection.universityLink ? (
                                        <Text type="secondary">
                                            <span> | </span>
                                            <a
                                                href={collection.universityLink}
                                                target="_blank">
                                                {collection.university.name}
                                            </a>{" "}
                                        </Text>
                                    ) : (
                                        <Text type="secondary">
                                            <span> | </span>
                                            {collection.university.name}
                                        </Text>
                                    )}
                                </>
                            ) : null}
                        </div>

                        {/* Description */}
                        <Text>{collection.certification.description}</Text>

                        {/* Objectives */}
                        {collection.certification?.objectives?.length > 0 && (
                            <div className="certification-objectives">
                                <h4 className="title">What you will learn</h4>
                                <ul className="objectives">
                                    {collection.certification?.objectives.map(
                                        (obj, i) => (
                                            <li key={i}>{obj}</li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}

                        {/* Min performance level */}
                        {collection.certification.minPerformLevel && (
                            <div className="min-performance-level">
                                <Alert
                                    message="Min. Performance Level"
                                    description={
                                        collection.certification.minPerformLevel
                                    }
                                    type="info"
                                    showIcon
                                />
                            </div>
                        )}
                    </div>

                    {collection.certification?.prerequisites?.length > 0 && (
                        <div className="prerequisites-section">
                            <h4 className="title">Prerequisites</h4>
                            <ul className="objectives">
                                {collection.certification?.prerequisites.map(
                                    (obj, i) => (
                                        <li key={i}>{obj}</li>
                                    )
                                )}
                            </ul>
                        </div>
                    )}
                </Col>
                <Col
                    className="right-panel"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={10}
                    xl={10}>
                    <>
                        <div className="fixed-section">
                            <div className="image-section">
                                {collection.certification && (
                                    <CertificateBadge />
                                )}
                                <CertificationCoverImage />
                            </div>

                            <div className="below-section">
                                {/* Issuer */}
                                <p className="issuer">
                                    <SafetyCertificateOutlined />{" "}
                                    <i>
                                        Certificate offered by CUbits, Inc,
                                        Princeton University startup
                                    </i>
                                </p>

                                {/* Duration */}
                                {certificateInfo &&
                                    certificateInfo.certificateStatus !==
                                        CERTIFICATE_STATUS.ISSUED && (
                                        <h2 className="duration">
                                            The course has a{" "}
                                            {collection.certification.duration}{" "}
                                            {collection.certification.duration >
                                            1
                                                ? "months"
                                                : "month"}{" "}
                                            learning period.{" "}
                                        </h2>
                                    )}

                                {/* Certificate Action Buttons */}
                                <CertificationActionButtons />

                                {/* Show dummy certificate  */}
                                {certificateInfo &&
                                    certificateInfo.certificateStatus ===
                                        CERTIFICATE_STATUS.ISSUED && (
                                        <DummyCertificate />
                                    )}
                            </div>
                        </div>
                    </>
                </Col>
            </Row>
        </div>
    );
};

// export default CertificationInfoCard;

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, { purchaseCollection })(
    CertificationInfoCard
);
