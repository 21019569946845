import amplitude from "amplitude-js";
import { IS_PROD } from "./const";

// initialize the client
const AMPLITUDE_API_KEY = "7e31a4cb8779fc0c4c4b34184680c655";
let amplitudeClient = null;

const trackingEvents = {
    appLoaded: "CUBIT::: APP_LOAD",
    pageView: "CUBIT::: PAGE_VIEW"
};

const getInstance = () => {
    return amplitude.getInstance();
};

// Init Ampliude
const initAmplitude = () => {
    if (!IS_PROD) return;
    const instance = getInstance();

    instance.setVersionName("Web");
    instance.init(
        AMPLITUDE_API_KEY,
        null,
        { includeReferrer: true, includeUtm: true, includeGclid: true },
        (client) => {
            amplitudeClient = client;
            logEvent(trackingEvents.appLoaded);
        }
    );
};

// Log event
const logEvent = (eventName, data = {}, user = undefined) => {
    if (!IS_PROD) return 99999;

    const instance = getInstance();
    if (!instance || !amplitudeClient) return 99999;

    // set URL to tracking data
    data = { ...data, url: window.location.href };

    // Bind logged user details
    if (user) {
        data.user = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            username: user.username
        };
    }

    // Bind Video information
    if (data.video) {
        data.video = {
            id: data.video.id,
            embedId: data.video.embedId,
            title: data.video.title,
            videoType: data.video.videoType
        };
    }

    // Bind Collection information
    if (data.collection) {
        data.collection = {
            id: data.collection.id,
            name: data.collection.name
        };
    }

    // Bind Module information
    if (data.module) {
        data.module = {
            id: data.module.id,
            name: data.module.name
        };
    }

    // console.log("track", eventName, data);
    return instance.logEvent(eventName, data);
};

export { initAmplitude, logEvent, trackingEvents };
