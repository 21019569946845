import CouponSearchModal from "./CouponSearchModal";
import {
    Button,
    Dropdown,
    Menu,
    Modal,
    Popconfirm,
    Space,
    Tooltip,
    Tree,
    Typography
} from "antd";
import {
    BookOutlined,
    BorderInnerOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    DoubleRightOutlined,
    DownloadOutlined,
    DownOutlined,
    EditOutlined,
    FolderOpenOutlined,
    FolderOutlined,
    PlayCircleOutlined,
    QuestionCircleOutlined,
    ThunderboltOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import TopUpForm from "../Wallet/TopUpForm";
import CouponManagementModal from "./CouponManagementModal";
import { purchaseCollection } from "../actions/user";
import { createBulkQuizQuestion } from "../actions/contentCreation";
import { connect } from "react-redux";
import { result } from "lodash";
import { CSVLink } from "react-csv";
import { API_URL } from "../const";
import BulkQuestionDrawer from "../QuestionsCreator/Bulk/BulkQuestionDrawer";
import Cupoints from "./Cupoints";

const { Paragraph, Text, Title } = Typography;

const CollectionInfoCardActions = ({
    collection,
    collectionNetPrice,
    currentUser,
    hasAccessToEntireCollection = false,
    isAuthenticated,
    mode,
    purchaseCollection,
    createBulkQuizQuestion,
    type = "default",
    redirectToCertificatePage = false
}) => {
    let history = useHistory();
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const [shareModalVisible, setShareModalVisible] = useState(false);
    const [visibleUploadForm, setVisibleUploadForm] = useState(false);

    const handleOnPurchaseClick = () => {
        // check for balance
        if (currentUser.primaryWallet.cupointsBalance >= collectionNetPrice) {
            purchaseCollection(collection, setPurchaseLoading);
        } else {
            setShowTopUpModal(true);
        }
    };

    const handleOnEditClick = () => {
        history.push(`/collections/${collection.id}/edit/`);
    };

    const gotoCertification = () => {
        history.push(`/collections/${collection.id}/certification/`);
    };

    const handleOnSelect = (selectedKeys, info) => {
        //console.log('selected', selectedKeys, info);
        navigator.clipboard.writeText(info.node.url);
    };

    const ClickableLink = ({ text }) => {
        const [copied, setCopied] = useState(false);
        return (
            <Tooltip
                title={
                    copied ? (
                        <>
                            <CheckCircleFilled style={{ color: "#52c41a" }} />{" "}
                            Copied
                        </>
                    ) : (
                        "Click to copy"
                    )
                }>
                <Text
                    onClick={() => {
                        if (!copied) {
                            setCopied(true);
                            setTimeout(() => setCopied(false), 1000);
                        }
                    }}>
                    {text}
                </Text>
            </Tooltip>
        );
    };

    const copyCollectionUrlToClipBoard = () => {
        let unlistedKey =
            collection.privacyScope === "unlisted"
                ? `?key=${collection.collectionUnlistedKey}`
                : "";
        const url = `${window.location.origin}/collections/${collection.id}/${unlistedKey}`;
        navigator.clipboard.writeText(url);
    };

    const ShareModal = () => {
        const courseStructure = () => {
            let unlistedKey =
                collection.privacyScope === "unlisted"
                    ? `?key=${collection.collectionUnlistedKey}`
                    : "";
            return [
                {
                    title: <ClickableLink text={collection.name} />,
                    key: `collection-${collection.id}`,
                    selectedType: "collection",
                    url: `${window.location.origin}/collections/${collection.id}/${unlistedKey}`,
                    icon: <BookOutlined />,
                    children: collection.modules.map((module, i) => {
                        return {
                            title: <ClickableLink text={module.name} />,
                            key: `module-${module.id}`,
                            url: `${window.location.origin}/collections/${
                                collection.id
                            }/modules/${i + 1}/${unlistedKey}`,
                            children: module.videos.map((video, j) => {
                                return {
                                    title: (
                                        <ClickableLink
                                            text={
                                                module.videoTitles[j].videoTitle
                                            }
                                        />
                                    ),
                                    url: `${
                                        window.location.origin
                                    }/collections/${collection.id}/modules/${
                                        i + 1
                                    }/videos/${j + 1}/${unlistedKey}`,
                                    key: `module-${module.id}-video-${video.id}`,
                                    icon: <PlayCircleOutlined />
                                };
                            })
                        };
                    })
                }
            ];
        };

        const renderCSVData = () => {
            let unlistedKey =
                collection.privacyScope === "unlisted"
                    ? `?key=${collection.collectionUnlistedKey}`
                    : "";
            let result = [];
            result.push([
                "Collection",
                collection.name,
                `${window.location.origin}/collections/${collection.id}/${unlistedKey}`
            ]);

            collection.modules.map((module, i) => {
                result.push([
                    "Module",
                    module.name,
                    `${window.location.origin}/collections/${
                        collection.id
                    }/modules/${i + 1}/${unlistedKey}`
                ]);

                module.videos.map((video, j) => {
                    result.push([
                        "Video",
                        module.videoTitles[j].videoTitle,
                        `${window.location.origin}/collections/${
                            collection.id
                        }/modules/${i + 1}/videos/${j + 1}/${unlistedKey}`
                    ]);
                });
            });
            return result;
        };
        return (
            <Modal
                onCancel={() => setShareModalVisible(false)}
                visible={shareModalVisible}
                footer={null}>
                <div className="modal-body">
                    <Title level={3}>Shareable Links</Title>
                    <Space direction="vertical">
                        <Text type="secondary">
                            Click the button below to download a CSV containing
                            links to your course
                        </Text>
                        <CSVLink
                            data={renderCSVData()}
                            filename={"cubits-collection-links.csv"}>
                            <Button icon={<DownloadOutlined />} type="primary">
                                Download CSV
                            </Button>
                        </CSVLink>
                    </Space>
                    <br />
                    <br />
                    <Text type="secondary">
                        Or alternatively, click on the names below to copy a
                        link to that page.
                    </Text>
                    <Tree
                        showIcon
                        switcherIcon={<DownOutlined />}
                        defaultExpandedKeys={["0-0-0"]}
                        onSelect={handleOnSelect}
                        treeData={courseStructure()}
                    />
                </div>
            </Modal>
        );
    };

    const handleOnSignupConfirm = () => {
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    const handleOnLoginConfirm = () => {
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    const handleMenuClick = (e) => {
        setShareModalVisible(true);
    };

    const shareMenu = () => (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                <ThunderboltOutlined /> Share Modules
            </Menu.Item>
        </Menu>
    );

    const InsufficientBalanceModalContent = () => {
        if (hasAccessToEntireCollection) {
            return (
                <div className="modal-body">
                    <Space>
                        <Title level={3}>
                            <UnlockOutlined />
                        </Title>
                        <Title level={3}>
                            All modules and videos are now unlocked.
                        </Title>
                    </Space>
                    <br />
                    <Text>Thank you for your purchase!</Text>
                </div>
            );
        } else if (
            currentUser.primaryWallet.cupointsBalance >= collectionNetPrice
        ) {
            return (
                <div style={{ padding: 25 }}>
                    <Space>
                        <Title level={3}>
                            <CheckCircleOutlined />
                        </Title>
                        <Title level={3}>Your balance is now sufficient</Title>
                    </Space>
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Button
                        type="primary"
                        loading={purchaseLoading}
                        icon={<ThunderboltOutlined />}
                        onClick={handleOnPurchaseClick}>
                        <Cupoints points={collectionNetPrice}>
                            Purchase all @
                        </Cupoints>
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ padding: 25 }}>
                    <Title level={3}>Insufficient Balance</Title>
                    <Space size={"large"}>
                        <Text>Looks like you are short</Text>
                        <Text>
                            {collectionNetPrice -
                                currentUser.primaryWallet.cupointsBalance}{" "}
                            cupoints
                        </Text>
                    </Space>

                    <br />
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Space direction="vertical">
                        <Text>
                            Click the button below to refill your balance.
                        </Text>
                        <TopUpForm marginTop={0} />
                    </Space>
                </div>
            );
        }
    };

    const CollectionPurchaseButton = () => {
        if (isAuthenticated && !hasAccessToEntireCollection) {
            return (
                <Space className="ant-space-responsive">
                    <Button
                        type="primary"
                        loading={purchaseLoading}
                        icon={<ThunderboltOutlined />}
                        onClick={handleOnPurchaseClick}
                        className="collection-info-card-action-button"
                        id="collection-purchase-btn">
                        <Cupoints points={collectionNetPrice}>
                            Purchase all @
                        </Cupoints>
                    </Button>

                    <CouponSearchModal
                        collection={collection}
                        hasAccessToEntireCollection={
                            hasAccessToEntireCollection
                        }
                    />

                    {/* If certification, show certification button */}
                    {collection.certification !== null && (
                        <CertificateViewButton />
                    )}
                </Space>
            );
        } else if (hasAccessToEntireCollection) {
            return (
                <Space className="ant-space-responsive">
                    <Button
                        type="primary"
                        loading={purchaseLoading}
                        icon={<CheckCircleOutlined />}
                        disabled
                        className="collection-info-card-action-button">
                        All Content Purchased
                    </Button>
                    <CouponSearchModal
                        collection={collection}
                        hasAccessToEntireCollection={
                            hasAccessToEntireCollection
                        }
                    />

                    {/* If certification, show certification button */}
                    {collection.certification !== null && (
                        <CertificateViewButton />
                    )}
                </Space>
            );
        } else {
            return (
                <Space className="ant-space-responsive">
                    <Popconfirm
                        title="You must be logged in to make a purchase."
                        onConfirm={handleOnSignupConfirm}
                        onCancel={handleOnLoginConfirm}
                        okText="Signup"
                        cancelText="Login">
                        <Button
                            type="primary"
                            loading={purchaseLoading}
                            icon={<ThunderboltOutlined />}
                            className="collection-info-card-action-button"
                            id="collection-purchase-btn">
                            <Cupoints points={collectionNetPrice}>
                                Purchase all @
                            </Cupoints>
                        </Button>
                    </Popconfirm>
                    <CouponSearchModal collection={collection} />
                    {/* If certification, show certification button */}
                    {collection.certification !== null && (
                        <CertificateViewButton />
                    )}
                </Space>
            );
        }
    };

    const CertificateViewButton = () => {
        return (
            <>
                <Button
                    key="certification"
                    id="collection-certification-button"
                    onClick={gotoCertification}
                    icon={<BookOutlined />}>
                    Get a course certification
                </Button>
            </>
        );
    };

    if (type === "list" && mode == "purchase") {
        return (
            <Space className="ant-space-responsive">
                <Button
                    type="primary"
                    onClick={() =>
                        history.push(
                            redirectToCertificatePage &&
                                collection.certification
                                ? `/collections/${collection.id}/certification/`
                                : `/collections/${collection.id}`
                        )
                    }>
                    View More
                </Button>
            </Space>
        );
    } else if (mode === "purchase") {
        return (
            <>
                <CollectionPurchaseButton />

                <Modal
                    visible={showTopUpModal}
                    onCancel={() => setShowTopUpModal(false)}
                    maskClosable={false}
                    footer={null}>
                    <InsufficientBalanceModalContent />
                </Modal>
                {isAuthenticated &&
                currentUser.isContentCreator &&
                collection.originalCollection ? (
                    <div>
                        <Space className="ant-space-responsive">
                            <CouponManagementModal
                                disabled={true}
                                collection={collection}
                                text={"Adopt this Course"}
                            />
                        </Space>
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    } else if (mode === "instructor") {
        return (
            <>
                <Space>
                    <Button
                        key="edit"
                        id="edit-collection-button"
                        onClick={handleOnEditClick}
                        icon={<EditOutlined />}>
                        Edit
                    </Button>

                    <CouponManagementModal collection={collection} />

                    <Tooltip title="Go to collection's main page">
                        <Button
                            icon={<DoubleRightOutlined />}
                            id="preview-collection-button"
                            onClick={() => history.push(collection.url)}>
                            Preview
                        </Button>
                    </Tooltip>

                    {/* <Button
                        type="primary"
                        icon={<ThunderboltOutlined />}
                        onClick={() => setShareModalVisible(true)}>
                        Share
                    </Button> */}
                    <ShareModal />

                    <Dropdown.Button
                        overlay={shareMenu}
                        onClick={copyCollectionUrlToClipBoard}
                        placement="bottomRight">
                        <>
                            <ThunderboltOutlined />{" "}
                            <ClickableLink text={"Share"} />
                        </>
                    </Dropdown.Button>

                    <Button
                        icon={<QuestionCircleOutlined />}
                        id="preview-collection-button"
                        onClick={() => setVisibleUploadForm(true)}>
                        Bulk Questions
                    </Button>

                    <BulkQuestionDrawer
                        visibleForm={visibleUploadForm}
                        handleDrawerClose={() => setVisibleUploadForm(false)}
                        collection={collection}
                        createBulkQuizQuestion={createBulkQuizQuestion}
                    />
                </Space>

                {/* <Space>

                <div className='d-flex'>
                    <h4>Collection Certification</h4>
                    {(collection.certification) ? (
                        <>
                            <Button
                                key="edit"
                                id="collection-certification-button"
                                onClick={handleOnEditClick}
                                icon={<EditOutlined />}
                            >
                                Edit Certification
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                key="edit"
                                id="collection-certification-button"
                                onClick={handleOnEditClick}
                                icon={<EditOutlined />}
                            >
                                Add Certification
                            </Button>
                        </>
                    )}
                </div>

            </Space> */}
            </>
        );
    } else if (mode === "preview") {
        return (
            <Space>
                <Button type="primary" disabled icon={<ThunderboltOutlined />}>
                    <Cupoints points={collectionNetPrice}>
                        Purchase all @
                    </Cupoints>
                </Button>
                <CouponSearchModal collection={collection} disabled={true} />
            </Space>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated
    };
};

export default connect(mapStateToProps, {
    purchaseCollection,
    createBulkQuizQuestion
})(CollectionInfoCardActions);
