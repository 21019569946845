import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Divider,
    List,
    Modal,
    Row,
    Space,
    Typography
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
import {
    fetchUserCreatedCollections,
    fetchUserCreatedModules
} from "./actions/contentCreation";
import LearningSVG from "./assets/images/authors-page/header-image.jpg";
import AuthorPageImage1 from "./assets/images/authors-page/author-page-image1.PNG";
import AuthorPageImage2 from "./assets/images/authors-page/author-page-image2.PNG";

import "./Components.less";
import { logEvent, trackingEvents } from "./Tracking";

const { Text, Title } = Typography;

class AuthorsPage extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        logEvent(trackingEvents.pageView, {
            page: "authors"
        });
    }

    render() {
        return (
            <section>
                <div className="author-page-landing">
                    <div id="author-page-heading">
                        <h1>
                            Become a cubits.ai <b>Author</b>
                        </h1>
                        <p>
                            Share your special skills with the world and
                            monetize. Create something unique.
                        </p>
                        <div id="author-page-landing-btns">
                            <Link to="/authors/" id="author-page-explore-btn">
                                Get Started
                            </Link>
                        </div>
                    </div>
                    <img
                        src={LearningSVG}
                        className="author-page-landing-image"
                    />
                </div>

                <div id="why-cubits-works" className="contain">
                    <h2>Why cubits.ai?</h2>
                    <section>
                        <p className="why-cubits-works-para">
                            We use AI in every aspect of the creative process.
                            All you need to do is to structure your thoughts as
                            bulleted lists and AI will create scripts,
                            teleprompters and facilitate the recordings from the
                            comfort of your own home/office.
                        </p>
                        <p className="why-cubits-works-para">
                            We help edit content, add special effects, package,
                            AI curate and place content on our site for
                            monetization.
                        </p>
                    </section>
                </div>

                <div id="how-you-get-paid" className="contain">
                    <h2>How You Get Paid</h2>
                    <p className="why-cubits-works-para heading">
                        All authors have their own page that shows how you are
                        selling content, with a micropayment model
                    </p>

                    <img src={AuthorPageImage1} />
                    <p className="why-cubits-works-para heading">
                        Your revenue is listed clearly with all breakdowns. Here
                        is a sample from a random author
                    </p>

                    <img src={AuthorPageImage2} />
                    <p className="why-cubits-works-para heading">
                        Your payments can be sent directly to your account
                        within 48-hours from purchase. No other company pays you
                        faster.
                    </p>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps, {})(AuthorsPage);
