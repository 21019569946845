import {
    Button,
    Card,
    Col,
    Modal,
    Popconfirm,
    Row,
    Space,
    Tooltip,
    Typography
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    CheckCircleOutlined,
    FrownOutlined,
    SmileOutlined,
    ThunderboltOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import VideoCardRenderer from "./VideoCardRenderer";
import { useState } from "react";
import { connect } from "react-redux";
import { purchaseModule } from "../actions/user";
import TopUpForm from "../Wallet/TopUpForm";
import ModalVideoPlayer from "./ModalVideoPlayer";
import { API_URL } from "../const";
import Cupoints from "./Cupoints";

const { Paragraph, Text, Title } = Typography;

const ModuleDisplay = ({
    collection,
    collectionId,
    currentUser,
    index,
    isAuthenticated,
    mode,
    module,
    moduleAccess,
    moduleIndex,
    purchaseModule,
    unlistedKey,
    certificationView = false
}) => {
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const [videoToPlay, setVideoPlay] = useState(false);
    const [visibleVideoPlayer, setVisibleVideoPlayer] = useState(false);

    let price = 0;

    if (mode === "purchase") {
        if (
            !isAuthenticated ||
            !moduleAccess[module.id] ||
            moduleAccess[module.id].accessType !== "permanent"
        ) {
            price += module.price;
        }
        module.licensedModules.forEach((licensedModule) => {
            if (
                !isAuthenticated ||
                !moduleAccess[licensedModule.id] ||
                moduleAccess[licensedModule.id].accessType !== "permanent"
            ) {
                price += licensedModule.price;
            }
        });
    } else if (mode === "preview") {
        price += module.price;
        /*module.licensedModules.forEach(licensedModule => {
            price += licensedModule.price
        })*/
    }

    const handleOnSignupConfirm = () => {
        alert(window.location.href);
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    const handleOnLoginConfirm = () => {
        window.location.href =
            API_URL + `/accounts/login/?next=${window.location.href}`;
        return;
    };

    const InsufficientBalanceModalContent = () => {
        if (
            moduleAccess[module.id] &&
            moduleAccess[module.id].accessType == "permanent"
        ) {
            return (
                <div className="modal-body">
                    <Space>
                        <Title level={3}>
                            <UnlockOutlined />
                        </Title>
                        <Title level={3}>All videos are now unlocked.</Title>
                    </Space>
                    <br />
                    <Text>Thank you for your purchase!</Text>
                </div>
            );
        } else if (currentUser.primaryWallet.cupointsBalance >= price) {
            return (
                <div style={{ padding: 25 }}>
                    <Space>
                        <Title level={3}>
                            <CheckCircleOutlined />
                        </Title>
                        <Title level={3}>Your balance is now sufficient</Title>
                    </Space>
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />

                    {!certificationView && (
                        <Button
                            type="primary"
                            loading={purchaseLoading}
                            icon={<ThunderboltOutlined />}
                            onClick={handleOnPurchaseClick}>
                            <Cupoints points={price}>
                                Purchase module @
                            </Cupoints>
                        </Button>
                    )}
                </div>
            );
        } else {
            return (
                <div style={{ padding: 25 }}>
                    <Title level={3}>Insufficient Balance</Title>
                    <Space size={"large"}>
                        <Text>Looks like you are short</Text>
                        <Text>
                            {price - currentUser.primaryWallet.cupointsBalance}{" "}
                            cupoints
                        </Text>
                    </Space>

                    <br />
                    <Space size={"large"}>
                        <Text type="secondary">Current Wallet Balance:</Text>
                        <Text type="secondary">
                            {currentUser.primaryWallet.cupointsBalance} cupoints
                        </Text>
                    </Space>
                    <br />
                    <br />
                    <Space direction="vertical">
                        <Text>
                            Click the button below to refill your balance.
                        </Text>
                        <TopUpForm marginTop={0} />
                    </Space>
                </div>
            );
        }
    };

    const ModuleExtraActions = () => {
        if (mode === "purchase") {
            return (
                <>
                    <ModulePurchaseActions />
                    <Modal
                        visible={showTopUpModal}
                        onCancel={() => setShowTopUpModal(false)}
                        maskClosable={false}
                        footer={null}>
                        <InsufficientBalanceModalContent />
                    </Modal>
                </>
            );
        } else if (mode === "preview") {
            return <ModulePreviewActions />;
        } else {
            return <></>;
        }
    };

    const handleOnPurchaseClick = () => {
        // check for balance
        if (currentUser.primaryWallet.cupointsBalance >= price) {
            purchaseModule(module.id, setPurchaseLoading);
        } else {
            setShowTopUpModal(true);
        }
    };

    const ModulePurchaseActions = () => {
        if (!isAuthenticated) {
            return (
                <Popconfirm
                    title="You must be logged in to make a purchase."
                    onConfirm={handleOnSignupConfirm}
                    onCancel={handleOnLoginConfirm}
                    okText="Signup"
                    cancelText="Login">
                    <Button
                        type="primary"
                        id={`purchase-module-btn-${index + 1}`}>
                        <Cupoints points={price}>Purchase @</Cupoints>
                    </Button>
                </Popconfirm>
            );
        }

        let currentDate = moment(new Date());

        if (
            moduleAccess[module.id] &&
            moduleAccess[module.id] &&
            moduleAccess[module.id].accessType == "permanent"
        ) {
            return (
                <Space className="ant-space-responsive">
                    <Button disabled>Purchased</Button>
                </Space>
            );
        } else if (
            moduleAccess[module.id] &&
            moduleAccess[module.id].accessType == "temporary"
        ) {
            let openDate = moment(
                new Date(moduleAccess[module.id].accessFrom)
            ).startOf("day");
            let closeDate = moment(
                new Date(moduleAccess[module.id].accessUntil)
            ).endOf("day");

            if (currentDate >= openDate && currentDate < closeDate) {
                return (
                    <Space className="ant-space-responsive">
                        <Button>
                            <SmileOutlined />
                            {`Free Access until ${closeDate.format(
                                "MM/DD/YYYY @ hh:mm A"
                            )}`}
                        </Button>
                        <Button
                            type="primary"
                            loading={purchaseLoading}
                            onClick={handleOnPurchaseClick}
                            id={`purchase-module-btn-${index + 1}`}>
                            <Cupoints points={price}>Purchase @</Cupoints>
                        </Button>
                    </Space>
                );
            } else {
                return (
                    <Space className="ant-space-responsive">
                        <Button danger>
                            <FrownOutlined />
                            {`Free Access expired on ${closeDate.format(
                                "MM/DD/YYYY @ hh:mm A"
                            )}`}
                        </Button>
                        <Button
                            type="primary"
                            loading={purchaseLoading}
                            onClick={handleOnPurchaseClick}
                            id={`purchase-module-btn-${index + 1}`}>
                            <Cupoints points={price}>Purchase @</Cupoints>
                        </Button>
                    </Space>
                );
            }
        } else {
            return (
                <Button
                    type="primary"
                    loading={purchaseLoading}
                    onClick={handleOnPurchaseClick}
                    id={`purchase-module-btn-${index + 1}`}>
                    <Cupoints points={price}>Purchase @</Cupoints>
                </Button>
            );
        }
    };

    const ModulePreviewActions = () => {
        return (
            <Button type="primary" disabled>
                <Cupoints points={price}>Purchase @</Cupoints>
            </Button>
        );
    };

    return (
        <div id={`module-${index + 1}`}>
            <Card>
                <div className="module-header">
                    <div>
                        <Title level={5} style={{ margin: 0 }}>
                            {module.name}
                        </Title>

                        {mode === "preview" ? (
                            <>
                                <Text type="secondary">{`${module.primaryAuthor.firstName} ${module.primaryAuthor.lastName}`}</Text>
                                <Text type="secondary">
                                    {module.coAuthors.map((coAuthor) => (
                                        <Text
                                            key={
                                                coAuthor.id
                                            }>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Text>
                                    ))}
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text type="secondary">
                                    By{" "}
                                    <Link
                                        to={`/creators/${module.primaryAuthor.id}`}>{`${module.primaryAuthor.firstName} ${module.primaryAuthor.lastName}`}</Link>
                                </Text>
                                <Text>
                                    {module.coAuthors.map((coAuthor) => (
                                        <Text key={coAuthor.id}>
                                            <Link
                                                to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link>
                                        </Text>
                                    ))}
                                </Text>
                            </>
                        )}
                    </div>
                    <div>{!certificationView && <ModuleExtraActions />}</div>
                </div>
            </Card>
            <br />
            <Row gutter={[16, 16]}>
                {module.videos.map((video, index) => {
                    return (
                        <Col
                            xs={24}
                            sm={12}
                            md={8}
                            lg={6}
                            key={`module-${module.id}-video-${index}`}>
                            <VideoCardRenderer
                                collection={collection}
                                collectionId={collectionId}
                                isAuthenticated={isAuthenticated}
                                mode={mode}
                                module={module}
                                moduleId={module.id}
                                moduleIndex={moduleIndex}
                                moduleName={module.name}
                                price={price}
                                video={video}
                                index={index}
                                moduleAccess={moduleAccess}
                                unlistedKey={unlistedKey}
                                playVideoInModal={(video) => {
                                    setVideoPlay(video);
                                    setVisibleVideoPlayer(true);
                                }}
                            />
                        </Col>
                    );
                })}
            </Row>
            <ModalVideoPlayer
                module={module}
                playVideoInModal={(video) => setVideoPlay(video)}
                toggleVideoPlayerModalVisibility={() =>
                    setVisibleVideoPlayer(!visibleVideoPlayer)
                }
                currentVideo={videoToPlay}
                visible={visibleVideoPlayer}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.user
    };
};
export default connect(mapStateToProps, { purchaseModule })(ModuleDisplay);
