import { Tooltip } from "antd";

const Cupoints = ({
    children,
    points,
    tooltipEnabled = true,
    secondaryWithinBrackets = true
}) => {
    return (
        <Tooltip
            placement="top"
            className="cupoints-tooltip"
            title={
                !tooltipEnabled
                    ? ""
                    : points === 0
                    ? `Free`
                    : `US $${points / 100} = ${points} cupoint${
                          points > 1 ? "s" : ""
                      }`
            }>
            <span>
                {children}
                {points === 0 ? (
                    <span className="cupoints-container">
                        <>Free</>
                    </span>
                ) : (
                    <span className="cupoints-container">
                        <span className="dollar-amount">
                            US ${points / 100}{" "}
                        </span>
                        <span className="cupoints-amount">
                            {secondaryWithinBrackets
                                ? `(${points} cupoint${points > 1 ? "s" : ""})`
                                : `${points} cupoint${points > 1 ? "s" : ""}`}
                        </span>
                    </span>
                )}
            </span>
        </Tooltip>
    );
};

export default Cupoints;
